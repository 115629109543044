import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Breadcrumb from "../../components/breadcrumb"

const ConduiteSupervisee = ({ data }) => (
  <Layout>
    <Seo title="Conduite Supervisée" breadcrumb={[
      { url: "nos-formations", name: "Nos Formations"},
      { url: "conduite-supervisee", name: "Conduite Supervisée"}
    ]} />
    <Breadcrumb paths={[{url: "/nos-formations/", name:"Nos formations"}, {url: null, name: "Conduite Supervisée"}]} />
    <h1 className="h1">Conduite supervisée</h1>
    <div
      className="text md:w-3/4 html-content"
      dangerouslySetInnerHTML={{ __html: data.strapiNosFormations.conduite_supervisee_page }}
    ></div>
  </Layout>
)

export default ConduiteSupervisee

export const pageQuery = graphql`
  query ConduiteSuperviseeQuery {
    strapiNosFormations {
      conduite_supervisee_page
    }
  }
`
